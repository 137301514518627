
import {defineComponent, computed} from 'vue'
import {useStore} from '@/store'
import ToolList from '@/components/ToolList.vue'

export default defineComponent({
  components: {
    ToolList,
  },
  setup() {
    const store = useStore()
    const tools = computed(() => store.state.tools.filteredList)
    const loading = computed(() => store.state.tools.list.length === 0)

    return {
      loading,
      tools,
    }
  },
})
