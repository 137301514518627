
import {defineComponent, ref, watch, onBeforeMount} from 'vue'
import {useStore} from '@/store'
import {useMq} from '@/composables/mq'
import AppHeader from '@/components/AppHeader.vue'
import AppSidebar from '@/components/AppSidebar.vue'

export default defineComponent({
  name: 'Default',
  components: {
    AppHeader,
    AppSidebar,
  },
  setup() {
    const store = useStore()
    onBeforeMount(() => store.dispatch('tools/fetch'))
    
    const mq = useMq()
    const showMenu = ref(false)
    watch(() => mq.desktop, (isDesktop) => showMenu.value = isDesktop)

    return {showMenu, mq}
  },
})
