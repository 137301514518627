
import {defineComponent, ref, computed, watch} from 'vue'
import {useStore} from '@/store'
import CategoryList from '@/components/CategoryList.vue'
import ToolList from '@/components/ToolList.vue'

export default defineComponent({
  components: {
    ToolList,
    CategoryList,
  },
  setup() {
    const store = useStore()

    const recentTools = computed(() => store.state.tools.recentList)
    const loading = computed(() => store.state.tools.list.length === 0)

    const categories = computed(() => store.state.tools.categories)
    const selectedCategories = ref([])
    const unwatch = watch(categories, categories => {
      selectedCategories.value = categories
      unwatch()
    })
    watch(selectedCategories, selectedCategories => {
      store.dispatch('tools/filter', selectedCategories)
    })

    return {
      recentTools,
      loading,
      categories,
      selectedCategories,
    }
  },
})
