
import type {PropType} from 'vue'

import {defineComponent, ref, computed} from 'vue'

export default defineComponent({
  props: {
    modelValue: {type: Array as PropType<string[]>},
    categories: {type: Array as PropType<(string | {value: string, label: string})[]>, required: true},
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const options = computed(() => {
      return props.categories.map(category => typeof category === 'string' ? {value: category, label: category} : category)
    })
    const search = ref('')
    const filteredOptions = computed(() => {
      const regexp = new RegExp(search.value, 'i')
      return options.value.filter(category => regexp.test(category.label))
    })

    const showAll = ref(false)
    const canShowAll = computed(() => filteredOptions.value.length > 5)

    const selectAll = computed({
      get: () => options.value.every(category => props.modelValue?.includes(category.value)),
      set: selectAll => emit('update:modelValue', selectAll ? options.value.map(category => category.value) : [])
    })
    const selected = computed({
      get: () => props.modelValue,
      set: value => emit('update:modelValue', value),
    })
    return {
      options,
      search,
      canShowAll,
      showAll,
      filteredOptions,
      selectAll,
      selected,
    }
  },
})
