
import type {PropType} from 'vue'
import type {Tool} from '@/types/Tool'

import {defineComponent} from 'vue'
import {useAnalytics} from '@/analytics'
import {useStore} from '@/store'

export default defineComponent({
  props: {
    tool: {type: Object as PropType<Tool | undefined>, required: true},
  },
  setup(){
    const store = useStore()
    const events = useAnalytics()

    const use = (toolId) => store.dispatch('tools/use', toolId)
  
    return {
      events,
      use
    }
  }
})
