import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import store from '@/store'
import Deafult from '@/views/Default.vue'
import Retool from '@/views/Retool.vue'
import Auth from '@/views/Auth.vue'
import Home from '@/views/Home.vue'
import ToolDetails from '@/views/ToolDetails.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/auth',
    name: 'auth',
    component: Auth,
    async beforeEnter(to, from, next) {
      if (await store.dispatch('user/isAuthorized')) next({name: 'home'})
      else next()
    },
  },
  {
    path: '/retool/:appId',
    name: 'retool',
    component: Retool,
    async beforeEnter(to, from, next) {
      if (!(await store.dispatch('user/isAuthorized'))) next({name: 'auth'})
      else next()
    },
  },
  {
    path: '/',
    component: Deafult,
    async beforeEnter(to, from, next) {
      if (!(await store.dispatch('user/isAuthorized'))) next({name: 'auth'})
      else next()
    },
    children: [
      {path: '/', name: 'home', component: Home},
      {path: '/tool/:toolId', name: 'tool', component: ToolDetails},
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  },
  routes,
})

export default router
