import {reactive, computed, onMounted} from 'vue'

const breakpoints = {
  mobile: 426,
  tablet: 1024,
  desktop: Infinity
}
type Size = keyof typeof breakpoints

const keys = Object.keys(breakpoints)
const values = [0, ...Object.values(breakpoints)]

interface Mq extends Record<Size, boolean>{
  name: Size
}

export function useMq(): Mq {
  const mq = reactive({
    name: 'mobile' as Size,
    ...keys.reduce((keys, name) => Object.assign(keys, {[name]: computed(() => mq.name === name)}), {})
  })

  onMounted(() => {
    keys.forEach((name: Size, index) => {
      let query = `(min-width: ${values[index]}px)`
      if (index < keys.length - 1) {
        query += ` and (max-width: ${values[index + 1] - 1}px)`
      }
      const mql = window.matchMedia(query)
      const listener = ({matches}) => {
        if (matches) mq.name = name
      }
      mql.addEventListener('change', listener)
      listener(mql)
    })
  })

  return mq
}
