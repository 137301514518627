
import {
  defineComponent,
  computed,
  watchEffect,
  watch,
  onBeforeMount,
  onMounted,
  onUnmounted,
  ref
} from 'vue'
import qs from 'qs'
import {useRoute} from 'vue-router'
import {useStore} from '@/store'
import {useAnalytics} from '@/analytics'

export default defineComponent({
  setup() {
    const route = useRoute()
    const store = useStore()
    const events = useAnalytics()
    const idToken = computed(() => store.state.user.idToken)
    const tool = computed(() => store.state.tools.list.find(tool => tool.docId === route.params.appId))
    const appUrl = computed(() => tool.value ? `${tool.value.url}?idToken=${idToken.value}` : null)
    const isRetoolApp = computed(() => appUrl.value && new URL(appUrl.value).origin === 'https://joinbranded.retool.com')
    const retoolMessageText = computed(() => location.hash.substr(1))
    const retoolContainer = ref(null)
    onBeforeMount(() => store.dispatch('tools/fetch'))

    onMounted(() => {
      window.addEventListener('message', handleEvents)
      onUnmounted(() => window.removeEventListener('message', handleEvents))

      function handleEvents({data}) {
        if (data.name === 'log') {
          console.log(data)
          events.custom(data.payload)
        }
        const isScorecardOrigin = data.origin === 'app-scorecard'
        if (isScorecardOrigin) {
          const uri = '#' + qs.stringify(data.data)
          history.replaceState(null, '', uri);
        }
      }
    })

    watchEffect(() => {
      if (tool.value) {
        document.title = tool.value.name
      }
    })

    watch(tool, () => {
      if (isRetoolApp.value) {
        // @ts-ignore
        window.retool.RetoolDashboard(retoolContainer.value, appUrl.value);
      }
    }, {flush: 'post'})

    return {appUrl, isRetoolApp, retoolContainer, retoolMessageText}
  },
})
