
import {defineComponent, ref} from 'vue'

export default defineComponent({
  setup() {
    const open = () => (window as any).Ybug.open()

    const g = ref(false)

    return {
      open,
      g,
    }
  },
})
