<template>
  <main class="auth">
    <ui-card class="card">
      <div class="image">
        <img src="https://cdn-images.welcometothejungle.com/1Y2Gkj2bKUlb10Hmb4xYCIiND6FkNO1bGGPj0-NNj_w/rs:auto:640::/q:85/czM6Ly93dHRqLXByb2R1Y3Rpb24vdXBsb2Fkcy9pbWFnZS9maWxlLzYyMDYvMTYxMTE1LzhhNmY4N2M1LTJmYjctNDJiZS1iZDQ0LWI5OTZhMGM5ZjVkZi5qcGc" />
      </div>
      <div class="content">
        <div class="logo">
          <img src="@/assets/images/logo.svg" />
        </div>
        <div class="form">
          <ui-button class="sign_in" type="outlined" :disabled="signingIn" @click="signIn">
            Sign in with Google
            <template #before="{iconClass}">
              <img v-if="!signingIn" :class="iconClass" src="@/assets/images/google.svg" />
              <ui-spinner v-else :class="iconClass" active />
            </template>
          </ui-button>
        </div>
      </div>
    </ui-card>
  </main>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue'
import {useStore} from '@/store'

export default defineComponent({
  setup() {
    const store = useStore()
    const signingIn = ref(false)
    const signIn = () => {
      signingIn.value = true
      store.dispatch('user/signIn').finally(() => signingIn.value = false)
    }
    return {signingIn, signIn}
  },
})
</script>

<style lang="scss" scoped>
@use "@/assets/style/theme.scss" as theme;

.auth {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  box-sizing: border-box;
  background-image: url('../assets/images/background.svg');
  background-position: center;
  background-repeat: no-repeat;

  .card {
    display: grid;
    grid-template-columns: 50% 50%;
    width: max(50vw, 700px);
    height: max(calc(50vw / 2), 350px);
    background-color: theme.$surface;
  }

  .image {
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .content {
    display: grid;
    grid-template-rows: auto 1fr;
    padding: 50px;
  }

  .form {
    align-self: center;
  }

  .sign_in {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .auth {
    .card {
      grid-template-columns: 1fr;
      grid-template-rows: 50% auto;
      width: max(50vw, 700px);
      height: auto;
    }

    .content {
      gap: 30px;
      padding: 30px;
    }
  }
}


@media (max-width: 480px) {
  .auth {
    padding: 0;
    .card {
      grid-template-columns: 1fr;
      grid-template-rows: 30% auto;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
