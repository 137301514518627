<template>
  <ui-fab class="feedback_button" extended icon="feedback" @click="open">
    <span class="text">Feedback</span>
  </ui-fab>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue'

export default defineComponent({
  setup() {
    const open = () => (window as any).Ybug.open()

    const g = ref(false)

    return {
      open,
      g,
    }
  },
})
</script>

<style lang="scss" scoped>
.feedback_button {
  position: fixed;
  bottom: 8px;
  right: 8px;
  padding-right: 0px;
  transition: padding .2s ease-in 1s;

  &:hover {
    padding-right: 20px;
    transition-delay: 0s;
    .text {
      width: 100px;
      transition-delay: 0s;
    }
  }
}

.text {
  display: block;
  width: 0;
  transition: width .2s ease-in 1s;
}
</style>