import {createApp} from 'vue'
import router from '@/router'
import store, {key} from '@/store'

import Balm from 'balm-ui'
import BalmPlus from 'balm-ui/dist/balm-ui-plus';

import App from '@/App.vue'

import '@/assets/style/index.scss'

const app = createApp(App)
app.use(router)
app.use(store, key)
app.use(Balm)
app.use(BalmPlus)

app.mount('#app')

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $tt(style: string): string;
  }
}

declare interface Window {
  retool: any
}