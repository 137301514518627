<template>
  <div :class="['default']">
    <app-header v-shadow="2" class="header" @menu="showMenu = !showMenu"/>
    <main>
      <transition :name="!mq.desktop ? 'slide' : null">
        <app-sidebar v-show="showMenu" class="sidebar" @close="showMenu = false"/>
      </transition>
      <router-view class="content"/>
    </main>
    <transition name="fade">
      <div v-show="showMenu" class="overlay" @click.self="showMenu = false"/>
    </transition>
  </div>
</template>

<script lang="ts">
import {defineComponent, ref, watch, onBeforeMount} from 'vue'
import {useStore} from '@/store'
import {useMq} from '@/composables/mq'
import AppHeader from '@/components/AppHeader.vue'
import AppSidebar from '@/components/AppSidebar.vue'

export default defineComponent({
  name: 'Default',
  components: {
    AppHeader,
    AppSidebar,
  },
  setup() {
    const store = useStore()
    onBeforeMount(() => store.dispatch('tools/fetch'))
    
    const mq = useMq()
    const showMenu = ref(false)
    watch(() => mq.desktop, (isDesktop) => showMenu.value = isDesktop)

    return {showMenu, mq}
  },
})
</script>

<style lang="scss" scoped>
@use '@/assets/style/theme.scss' as theme;

.default {
  --header-size: 64px;
  --sidebar-size: 370px;

  min-height: 100vh;

  &.overlayed {
    overflow: hidden;
  }
}


.header {
  position: sticky;
  top: 0;
  z-index: 90;
}

.overlay {
  @media (max-width: 1025px) {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, .5);
    z-index: 95;
  }
}

main {
  display: flex;
}

.sidebar {
  position: sticky;
  top: var(--header-size);
  flex: 0 0 auto;
  width: var(--sidebar-size);
  max-width: 100vw;
  height: calc(100vh - var(--header-size));
  background-color: theme.$surface;
  z-index: 80;
  opacity: 1;

  @media (max-width: 1025px) {
    position: absolute;
    height: 100vh;
    top: 0;
    z-index: 100;
  }
}

.content {
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
}

.slide-enter-active, .slide-leave-active {
  transition: transform .2s ease-out;
}

.slide-enter-from, .slide-leave-to {
  transform: translateX(-100%);
}

.slide-enter-to, .slide-leave-from {
  transform: translateX(0);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .15s ease-out;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.fade-enter-to, .fade-leave-from {
  opacity: 1;
}
</style>
