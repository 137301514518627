
import {defineComponent, computed} from 'vue'
import {useRoute} from 'vue-router'
import {useStore} from '@/store'
import ToolView from '@/components/ToolView.vue'

export default defineComponent({
  components: {
    ToolView,
  },
  setup() {
    const route = useRoute()
    const store = useStore()

    const tool = computed(() => {
      const {toolId} = route.params
      return store.state.tools.list.find(tool => tool.docId === toolId)
    })

    return {
      tool,
    }
  },
})
