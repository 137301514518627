<template>
  <div class="blocked_message">
    <h3 class="title">Sorry you not allowed to continue!</h3>
    <p>Your email should be registred under one of the organizations supported by the Branded Platform.</p>
    <p>Please ensure this is a corect email:</p>
    <div class="email">{{ user.email }}</div>
    <div class="actions">
      <p class="support">
        If you have any questions about this, please contact
        <a class="contact" href="mailto:it-support@joinbranded.com">it-support@joinbranded.com</a>
      </p>
      <VButton @click="signOut">Sign out</VButton>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, computed} from 'vue'
import {useStore} from '@/store'

export default defineComponent({
  name: 'BlockedMessage',
  setup() {
    const store = useStore()
    const user = computed(() => store.state.user.data)
    const signOut = () => store.dispatch('user/signOut')
    return {user, signOut}
  },
})
</script>

<style lang="scss" scoped>
.blocked_message {
  font-size: 18px;

  p {
    margin: 10px 0;
  }

  .title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 30px;
  }

  .email {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    margin: 20px 0;
  }

  .support {
    font-size: 14px;
    color: var(--color-text-secondary);
  }

  .contact {
    color: var(--color-primary);
  }

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 10px;
  }
}
</style>