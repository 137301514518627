<template>
  <ui-list v-if="readed.length > 0 || unreaded.length > 0" type="twoLines" avatar>
    <ui-item v-for="notification in unreaded" :key="notification.id">
      <template #before="{iconClass}">
        <avatar :class="iconClass" compact :image="notification.icon" :name="notification.name"/>
      </template>
      <ui-item-text-content class="content">
        <ui-item-text1>{{notification.name}}</ui-item-text1>
        <ui-item-text2>{{notification.text}}</ui-item-text2>
      </ui-item-text-content>
      <ui-item-last-content>
        <ui-badge>
          <template #badge>NEW</template>
        </ui-badge>
      </ui-item-last-content>
    </ui-item>
    <ui-item-divider v-if="readed.length > 0 && unreaded.length > 0" />
    <ui-item v-for="notification in readed" :key="notification.id">
      <template #before="{iconClass}">
        <avatar :class="iconClass" compact :image="notification.icon" :name="notification.name"/>
      </template>
      <ui-item-text-content class="content">
        <ui-item-text1>{{notification.name}}</ui-item-text1>
        <ui-item-text2>{{notification.text}}</ui-item-text2>
      </ui-item-text-content>
    </ui-item>
  </ui-list>
  <div v-else :class="['placeholder', $tt('headline6')]">
    No notifications yet
  </div>
</template>

<script lang="ts">
import {defineComponent, computed, onUnmounted} from 'vue'
import {useStore} from '@/store'
import Avatar from '@/components/Avatar.vue'

export default defineComponent({
  components: {
    Avatar
  },
  setup() {
    const store = useStore()

    const unreaded = computed(() => store.getters['notifications/unreaded'])
    const readed = computed(() => store.getters['notifications/readed'])

    onUnmounted(() => store.dispatch('notifications/read', unreaded.value.map(notification => notification.id)))

    return {unreaded, readed}
  },
})
</script>

<style lang="scss" scoped>
.content {
  ::v-deep(.mdc-deprecated-list-item__primary-text::before) {
    height: 0;
  }
}

.placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 48px 12px;
  box-sizing: border-box;
  opacity: .4;
}
</style>