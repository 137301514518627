import type {InjectionKey} from 'vue'
import {Store} from 'vuex'

import {createStore, useStore as useStoreBase} from 'vuex'
import user, {UserState} from '@/store/user'
import tools, {ToolsState} from '@/store/tools'
import notifications, {NotificationsState} from '@/store/notifications'

export interface State {
  user: UserState,
  tools: ToolsState
  notifications: NotificationsState,
}

export default createStore<State>({
  modules: {
    user,
    tools,
    notifications,
  },
})

export const key: InjectionKey<Store<State>> = Symbol()

export function useStore() {
  return useStoreBase(key)
}