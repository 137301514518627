import type {Tool} from '@/types/Tool'

import {firestore} from '@/firebase'

export interface ToolsState {
  list: Tool[],
  filteredList: Tool[],
  recentList: Tool[],
  categories: string[]
}

export default {
  namespaced: true,
  state: () => ({
    list: [],
    filteredList: [],
    recentList: [],
    categories: [],
  }),
  mutations: {
    fetched(state, tools) {
      state.list = tools
      const categories = tools.reduce((categories, tool) => {
        tool?.labels.forEach(label => label && categories.add(label))
        return categories
      }, new Set())
      state.categories = Array.from(categories)
    },
    filtered(state, tools) {
      state.filteredList = tools
    },
    used(state, toolIds) {
      state.recentList = toolIds.reduce((tools, toolId) => {
        const tool = state.list.find(tool => tool.docId === toolId)
        if (tool) tools.push(tool)
        return tools
      }, [])
    }
  },
  actions: {
    async fetch({commit, rootState}) {
      try {
        const {docs} = await firestore.collection('tools').get()
        const tools = docs
          .map(doc => {
            const data = doc.data()
            data.docId = doc.id
            data.isRetool = data.url.includes('retool.com')
            return data
          })
          .sort((a, b) => {
            if (a.priority > b.priority) return -1
            else if (a.priority < b.priority) return 1
            else return (a.rank || Infinity) < (b.rank || Infinity) ? -1 : 1
          })

        commit('fetched', tools)

        const userDoc = await firestore.collection('users').doc(rootState.user.data.uid).get()
        const {recentlyUsedTools = []} = await userDoc.data()
        commit('used', recentlyUsedTools)
      } catch (err) {
        console.error(err)
      }
    },
    async filter({state, commit}, categories) {
      const filteredList = state.list.filter(tool => tool.labels.some(label => categories.includes(label)))
      commit('filtered', filteredList)
    },
    async use({state, commit, rootState}, toolId) {
        const recentlyUsedTools = state.recentList.map(tool => tool.docId)
        const toolIndex = state.recentList.findIndex(tool => tool.docId === toolId)
        if (toolIndex >=0 || recentlyUsedTools.length >= 4) recentlyUsedTools.splice(toolIndex, 1)
        recentlyUsedTools.unshift(toolId)
        await firestore.collection('users').doc(rootState.user.data.uid).update({recentlyUsedTools})
        commit('used', recentlyUsedTools)
    }
  }
}