<template>
  <div class="sidebar">
    <div class="branding">
      <ui-icon-button class="close" @click="$emit('close')">arrow_back</ui-icon-button>
      <router-link :to="{name: 'home'}" class="logo">
        <img src="@/assets/images/logo.svg" />
      </router-link>
    </div>
    <div class="content">
      <h5 :class="[$tt('headline5'), 'title']">Category Filters</h5>
      <category-list :categories="categories" v-model="selectedCategories" />
      <template v-if="recentTools.length > 0">
        <h5 :class="[$tt('headline5'), 'title']">Recently Used Tools</h5>
        <tool-list class="recently" compact :loading="loading" :tools="recentTools" />
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, ref, computed, watch} from 'vue'
import {useStore} from '@/store'
import CategoryList from '@/components/CategoryList.vue'
import ToolList from '@/components/ToolList.vue'

export default defineComponent({
  components: {
    ToolList,
    CategoryList,
  },
  setup() {
    const store = useStore()

    const recentTools = computed(() => store.state.tools.recentList)
    const loading = computed(() => store.state.tools.list.length === 0)

    const categories = computed(() => store.state.tools.categories)
    const selectedCategories = ref([])
    const unwatch = watch(categories, categories => {
      selectedCategories.value = categories
      unwatch()
    })
    watch(selectedCategories, selectedCategories => {
      store.dispatch('tools/filter', selectedCategories)
    })

    return {
      recentTools,
      loading,
      categories,
      selectedCategories,
    }
  },
})
</script>

<style lang="scss" scoped>
@use '@/assets/style/theme.scss' as theme;

.sidebar {
  height: 100%;
  overflow: auto;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.branding {
  display: none;
  position: sticky;
  top: 0;
  z-index: 90;
  background-color: theme.$surface;

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 8px 16px;
    box-sizing: border-box;
  }
}
.content {
  padding: 16px;
}

.title {
  margin-bottom: 12px;
}

</style>