
import {defineComponent, computed} from 'vue'

export default defineComponent({
  props: {
    image: {type: String},
    name: {type: String},
    compact: {type: Boolean},
  },
  setup(props) {
    const fallback = computed(() => {
      const [firstName = '', lastName = ''] = props.name.split(' ', 2)
      return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`
    })

    return {fallback}
  },
})
