<template>
  <div class="category_list">
    <div class="header">
      <ui-textfield v-model="search" class="search" input-type="search" icon="search" type="outlined" fullwidth placeholder="Search categories" />
      <ui-form-field>
        <ui-checkbox v-model="selectAll" input-id="select_all"></ui-checkbox>
        <label for="select_all">Select all</label>
      </ui-form-field>
      <ui-divider class="divider" />
    </div>
    <ui-skeleton :loading="options.length === 0" active :paragraph="{rows: 5}">
      <ul role="group">
        <li v-for="{value, label} in (!showAll ? filteredOptions.slice(0, 5) : filteredOptions)" :key="value">
          <ui-form-field>
            <ui-checkbox v-model="selected" :value="value" :input-id="`checkbox-${value}`"></ui-checkbox>
            <label :for="`checkbox-${value}`">{{label}}</label>
          </ui-form-field>
        </li>
      </ul>
    </ui-skeleton>
    <div class="footer">
      <ui-button :disabled="!canShowAll" @click="showAll = !showAll">
        {{showAll ? 'Show less' : 'Show more'}}
        <template #before="{iconClass}">
          <ui-icon :class="[iconClass]">{{showAll ? 'expand_less' : 'expand_more'}}</ui-icon>
        </template>
      </ui-button>
    </div>
  </div>
</template>

<script lang="ts">
import type {PropType} from 'vue'

import {defineComponent, ref, computed} from 'vue'

export default defineComponent({
  props: {
    modelValue: {type: Array as PropType<string[]>},
    categories: {type: Array as PropType<(string | {value: string, label: string})[]>, required: true},
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const options = computed(() => {
      return props.categories.map(category => typeof category === 'string' ? {value: category, label: category} : category)
    })
    const search = ref('')
    const filteredOptions = computed(() => {
      const regexp = new RegExp(search.value, 'i')
      return options.value.filter(category => regexp.test(category.label))
    })

    const showAll = ref(false)
    const canShowAll = computed(() => filteredOptions.value.length > 5)

    const selectAll = computed({
      get: () => options.value.every(category => props.modelValue?.includes(category.value)),
      set: selectAll => emit('update:modelValue', selectAll ? options.value.map(category => category.value) : [])
    })
    const selected = computed({
      get: () => props.modelValue,
      set: value => emit('update:modelValue', value),
    })
    return {
      options,
      search,
      canShowAll,
      showAll,
      filteredOptions,
      selectAll,
      selected,
    }
  },
})
</script>

<style lang="scss" scoped>

.search {
  margin-bottom: 8px;
}

.divider {
  margin: 8px 0;
}

.footer {
  display: flex;
  justify-content: flex-end;
}

</style>
