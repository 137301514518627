
import {defineComponent, ref} from 'vue'
import {useStore} from '@/store'

export default defineComponent({
  setup() {
    const store = useStore()
    const signingIn = ref(false)
    const signIn = () => {
      signingIn.value = true
      store.dispatch('user/signIn').finally(() => signingIn.value = false)
    }
    return {signingIn, signIn}
  },
})
