<template>
  <ui-card :class="['tool_card', {compact}]">
    <ui-card-content
      class="content"
      @click="$router.push({name: 'tool', params: {toolId: tool.docId}}), events.openTool(tool)"
    >
      <ui-card-media class="media" square>
        <ui-card-media-content>
          <img v-if="tool.icon" class="icon" :src="tool.icon" :alt="tool.name" />
          <div v-else class="placeholder">No Image</div>
        </ui-card-media-content>
      </ui-card-media>
      <ui-card-text class="text">
        <h6 :class="[$tt('headline6'), 'name']">{{tool.name}}</h6>
        <div v-if="!compact" :class="[$tt('body2'), 'description']">{{tool.shortDescription}}</div>
        <div v-else :class="[$tt('body2'), 'pm']"><span class="sub_title">PM:</span> {{tool.pm}}</div>
      </ui-card-text>
    </ui-card-content>
    <ui-card-actions v-if="!compact" >
      <router-link class="launch" v-if="tool.isRetool" :to="{name: 'retool', params: {appId: tool.docId}}">
        <ui-button fullwidth type="unelevated" @click="events.launchTool(tool), use(tool.docId)">Launch</ui-button>
      </router-link>
      <a v-else class="launch" :href="tool.url" target="_blank">
        <ui-button fullwidth type="unelevated" @click="events.launchTool(tool), use(tool.docId)">Launch</ui-button>
      </a>
    </ui-card-actions>
  </ui-card>
</template>

<script lang="ts">
import type {PropType} from 'vue'
import type {Tool} from '@/types/Tool'

import {defineComponent, computed} from 'vue'
import {useAnalytics} from '@/analytics'
import {useStore} from '@/store'

export default defineComponent({
  props: {
    compact: {type: Boolean},
    tool: {type: Object as PropType<Tool>},
  },
  setup() {
    const store = useStore()
    const events = useAnalytics()
    const use = (toolId) => store.dispatch('tools/use', toolId)

    return {
      events,
      use
    }
  }
})
</script>

<style lang="scss" scoped>
.icon {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.content {
  height: 100%;

  @media (max-width: 425px) {
    flex-direction: row;
  }
}

.media {
  @media (max-width: 425px) {
    flex: 1 0 33.3%;

    .tool_card.compact & {
      flex: 1 0 20%;
    }
  }
}

.text {
  @media (max-width: 425px) {
    flex: 1 1 100%;
  }

  .compact & {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.name {
  margin-bottom: 8px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  flex-grow: 1;

  .tool_card.compact & {
    -webkit-line-clamp: 3;
  }
}

.description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  @media (max-width: 425px) {
    -webkit-line-clamp: 2;
  }
}

.pm {
  text-transform: capitalize;

  .sub_title {
    font-weight: 600;
    text-transform: uppercase;
  }
}

.launch {
  width: 100%;

  > * {
    width: 100%;
  }
}
</style>