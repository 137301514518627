<template>
  <div class="header">
    <div class="branding">
      <ui-icon-button class="menu" @click="$emit('menu')">menu</ui-icon-button>
      <router-link v-if="mq.desktop" :to="{name: 'home'}">
        <img src="@/assets/images/logo.svg" />
      </router-link>
    </div>
    <ui-autocomplete
      v-if="mq.tablet || mq.desktop"
      v-model="search"
      class="search"
      icon="search"
      outlined
      autofocus
      highlight
      placeholder="Search Tools"
      :source="autocomplete"
      @input="events.search(search)"
      @selected="enterTool"
    />
    <div class="profile">
      <popup :open="notificationsOpen" @close="notificationsOpen = false">
        <template #target>
          <ui-icon-button @click="notificationsOpen = !notificationsOpen">
              <ui-badge :class="['notification_badge', {hide: !newNotificationsCount}]" overlap :count="newNotificationsCount">
                <ui-icon :type="notificationsOpen ? 'filled' : 'outlined'">notifications</ui-icon>
              </ui-badge>
          </ui-icon-button>
        </template>
        <ui-card v-shadow="4">
          <notification-list class="notification_list" />
        </ui-card>
      </popup>
      <ui-icon-button icon="logout" @click="signOut" />
      <avatar v-if="user" class="avatar" size="large" :image="user.image" :name="user.name" />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, ref, computed, onBeforeMount} from 'vue'
import {useRouter} from 'vue-router'
import {useStore} from '@/store'
import {useMq} from '@/composables/mq'
import {useAnalytics} from '@/analytics'
import Avatar from '@/components/Avatar.vue'
import Popup from '@/components/Popup.vue'
import NotificationList from '@/components/NotificationList.vue'

export default defineComponent({
  components: {
    Avatar,
    Popup,
    NotificationList,
  },
  emits: ['menu'],
  setup() {
    const mq = useMq()
    const store = useStore()
    const router = useRouter()
    const events = useAnalytics()

    const search = ref('')
    const autocomplete = computed(() => {
      if (!search.value) return []
      const regexp = new RegExp(search.value, 'i')
      return store.state.tools.list.reduce((variants, tool) => {
        if (regexp.test(tool.name)) variants.push({value: tool.docId, label: tool.name})
        return variants
      }, [])
    })
    const enterTool = ({value}) => {
      search.value = ''
      router.push({name: 'tool', params: {toolId: value}})
    }

    onBeforeMount(() => {
      store.dispatch('notifications/fetch')
      store.dispatch('notifications/watch')
    })
    const newNotificationsCount = computed(() => store.getters['notifications/unreaded'].length)
    const notificationsOpen = ref(false)

    const user = computed(() => store.state.user.data)
    const signOut = () => store.dispatch('user/signOut')

    return {
      mq,
      search,
      autocomplete,
      enterTool,
      newNotificationsCount,
      notificationsOpen,
      user,
      signOut,
      events
    }
  },
})
</script>

<style lang="scss" scoped>
@use '@/assets/style/theme.scss' as theme;

.header {
  display: grid;
  grid-template-columns: calc(var(--sidebar-size) - 16px) 1.5fr auto;
  align-items: center;
  gap: 16px;
  padding: 8px 16px;
  box-sizing: border-box;
  background-color: theme.$surface;

  @media (max-width: 1025px) {
    grid-template-columns: auto 1fr auto;
    padding: 8px;
  }

  @media (max-width: 426px) {
    padding: 4px 8px;
  }
}

.branding {
  display: flex;
  align-items: center;
  gap: 16px;
}

.search {
  height: 100%;
  max-width: 700px;
  padding: 0;

  ::v-deep(.mdc-text-field__plus) {
    margin-top: -8px;
  }
}

.profile {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

.notification_badge {
  ::v-deep(.mdc-badge__sup) {
    font-family: 'Open Sans'
  }

  &.hide::v-deep(.mdc-badge__sup) {
    display: none;
  }
}

.notification_list {
  width: 340px;

  @media (max-width: 425px) {
    width: calc(100vw - 10px);
  }
}
</style>
