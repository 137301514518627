import type {Notification} from '@/types/Notification'

import {firestore} from '@/firebase'

export interface NotificationsState {
  notifications: Map<string, Notification>
}

export default {
  namespaced: true,
  state: () => ({
    notifications: new Map<string, Notification>()
  }),
  getters: {
    readed(state: NotificationsState) {
      return Array.from(state.notifications.values()).filter(notification => notification.isReaded)
    },
    unreaded(state: NotificationsState) {
      return Array.from(state.notifications.values()).filter(notification => !notification.isReaded)
    },
  },
  mutations: {
    fetched(state, notifications) {
      notifications.forEach(notification => state.notifications.set(notification.id, notification))
    },
    added(state, notification) {
      state.notifications.set(notification.id, notification)
    },
    modified(state, notification) {
      state.notifications.set(notification.id, notification)
    },
    removed(state, notification) {
      state.notifications.delete(notification.id)
    },
    readed(state, notificationIds) {
      notificationIds.forEach(notificationId => {
        const notification = state.notifications.get(notificationId)
        if (notification) notification.isReaded = true
      })
    }
  },
  actions: {
    async watch({commit, dispatch, rootState}): Promise<() => void | null> {
      if (!await dispatch('user/isAuthorized', null, {root: true})) return null
      const collection = firestore.collection('users').doc(rootState.user.data.uid).collection('notifications')
      const cancel = collection.onSnapshot(snapshot => {
        snapshot.docChanges().forEach(({type, doc}) => commit(type, {id: doc.id, ...doc.data()}))
      })
      return cancel
    },

    async fetch({commit, dispatch, rootState}) {
      if (!await dispatch('user/isAuthorized', null, {root: true})) return
      const collection = firestore.collection('users').doc(rootState.user.data.uid).collection('notifications')
      const notifications = await collection.get().then(({docs}) => docs.map(doc => ({id: doc.id, ...doc.data()})))
      commit('fetched', notifications)
    },

    async read({commit, rootState}, notificationIds) {
      const collection = firestore.collection('users').doc(rootState.user.data.uid).collection('notifications')
      commit('readed', notificationIds)
      await Promise.all(notificationIds.map(notificationId => {
        return collection.doc(notificationId).update({isReaded: true})
      }))
    }
  }
}