import router from '@/router'
import firebase, {auth, firestore} from '@/firebase'
import {events} from '@/analytics'

const ALLOWED_DOMAINS = new Set(['joinbranded.com'])

export interface UserState {
  data: any,
  status: 'unknown' | 'unauthorized' | 'authorized' | 'blocked',
  idToken: string,
  initialization?: Promise<boolean>
}

export default {
  namespaced: true,
  state: () => ({
    data: null,
    status: 'unknown',
    idToken: null,
    initialization: null,
  }),
  mutations: {
    initialization(state, initialization) {
      state.initialization = initialization
    },
    blocked(state, user) {
      state.status = 'blocked'
      state.data = {
        uid: user.uid,
        email: user.email,
        name: user.displayName,
        image: user.photoURL,
      }
    },
    authorized(state, user) {
      state.status = 'authorized'
      state.data = {
        uid: user.uid,
        email: user.email,
        name: user.displayName,
        image: user.photoURL,
      }
    },
    unauthorized(state) {
      state.status = 'unauthorized'
      state.data = null
    },
    idTokenChanged(state, idToken) {
      state.idToken = idToken
    }
  },
  actions: {
    async isAuthorized({state, dispatch}) {
      if (state.status === 'unknown') {
        if (!state.initialization) dispatch('auth')
        return state.initialization
      }
      return state.status === 'authorized'
    },
    
    async auth({commit,dispatch, state}) {
      if (state.status !== 'unknown') return
      const initialization = new Promise(resolve => {
        const unsubscribe = auth.onAuthStateChanged(async user => {
          unsubscribe()
          if (!user) {
            commit('unauthorized')
            return resolve(false)
          }
          const userDoc = firestore.collection('users').doc(user.uid)
          const userData = await userDoc.get().then(user => user?.data())
          if (ALLOWED_DOMAINS.has(userData?.domain)) {
            dispatch('trackIdToken')
            commit('authorized', user)
            return resolve(true)
          } else {
            commit('blocked', user)
            return resolve(false)
          }
        })
      })
      commit('initialization', initialization)
    },
    
    async signIn({commit, dispatch}) {
      try {
        const provider = new firebase.auth.GoogleAuthProvider()
        const {user} = await auth.signInWithPopup(provider)

        const response = await fetch('https://us-central1-branded-retool.cloudfunctions.net/api/check-email', {
          method: 'post',
          headers: {
            Authorization: `Bearer ${await user.getIdToken()}`
          }
        })

        if (response.status === 201) {
          dispatch('trackIdToken')
          commit('authorized', user)
          router.push({name: 'home'})
          events.signIn()
        } else {
          commit('blocked', user)
        }
      } catch (err) {
        console.error(err)
      }
    },
    
    async signOut({commit}) {
      try {
        await fetch('https://us-central1-branded-retool.cloudfunctions.net/api/auth/revoke', {
          method: 'post',
          headers: {
            Authorization: `Bearer ${await auth.currentUser.getIdToken()}`
          }
        })
        await auth.signOut()
        commit('unauthorized')
        router.push({name: 'auth'})
      } catch (err) {
        console.error(err)
      }
    },

    async trackIdToken({commit}) {
      if (auth.currentUser) {
        commit('idTokenChanged', auth.currentUser.getIdToken())
      }
      auth.onIdTokenChanged(async user => {
        commit('idTokenChanged', user ? await user.getIdToken() : null)
      })
    }
  },
}