
import {defineComponent, computed} from 'vue'
import {useStore} from '@/store'

export default defineComponent({
  name: 'BlockedMessage',
  setup() {
    const store = useStore()
    const user = computed(() => store.state.user.data)
    const signOut = () => store.dispatch('user/signOut')
    return {user, signOut}
  },
})
