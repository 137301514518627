
import {defineComponent, computed, onUnmounted} from 'vue'
import {useStore} from '@/store'
import Avatar from '@/components/Avatar.vue'

export default defineComponent({
  components: {
    Avatar
  },
  setup() {
    const store = useStore()

    const unreaded = computed(() => store.getters['notifications/unreaded'])
    const readed = computed(() => store.getters['notifications/readed'])

    onUnmounted(() => store.dispatch('notifications/read', unreaded.value.map(notification => notification.id)))

    return {unreaded, readed}
  },
})
