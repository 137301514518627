<template>
  <div class="tool_details">
    <div class="navigation">
      <router-link :to="{name: 'home'}"><ui-icon-button icon="arrow_back"/></router-link>
      <h4 v-if="tool" :class="[$tt('headline4')]">{{ tool.name }}</h4>
    </div>
    <tool-view :tool="tool" />
  </div>
</template>

<script lang="ts">
import {defineComponent, computed} from 'vue'
import {useRoute} from 'vue-router'
import {useStore} from '@/store'
import ToolView from '@/components/ToolView.vue'

export default defineComponent({
  components: {
    ToolView,
  },
  setup() {
    const route = useRoute()
    const store = useStore()

    const tool = computed(() => {
      const {toolId} = route.params
      return store.state.tools.list.find(tool => tool.docId === toolId)
    })

    return {
      tool,
    }
  },
})
</script>

<style lang="scss" scoped>
.navigation {
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  > :not(:last-child) {
    margin-right: 12px;
  }
}
</style>