<template>
  <ui-skeleton :loading="loading" active :paragraph="{rows: compact ? 6 : 24}">
    <div v-if="tools.length > 0" :class="['tools', {compact}]">
      <tool-card v-for="tool in tools" :key="tool.docId" :tool="tool" :compact="compact" />
    </div>
    <div v-else :class="$tt('headline6')">
      No tools
    </div>
  </ui-skeleton>
</template>

<script lang="ts">
import type {PropType} from 'vue'
import type {Tool} from '@/types/Tool'

import {defineComponent} from 'vue'
import ToolCard from '@/components/ToolCard.vue'

export default defineComponent({
  components: {
    ToolCard,
  },
  props: {
    compact: {type: Boolean},
    loading: {type: Boolean},
    tools: {type: Array as PropType<Tool[]>, required: true},
  },
})
</script>

<style lang="scss" scoped>
.tools {
  --tools-item-width: 180px;

  &.compact {
    --tools-item-width: 150px;
  }

  @media (max-width: 425px) {
    --tools-item-width: 100%!important;
  }

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--tools-item-width), 1fr));
  gap: 16px;
}
</style>
