
import type {PropType} from 'vue'
import type {Tool} from '@/types/Tool'

import {defineComponent} from 'vue'
import ToolCard from '@/components/ToolCard.vue'

export default defineComponent({
  components: {
    ToolCard,
  },
  props: {
    compact: {type: Boolean},
    loading: {type: Boolean},
    tools: {type: Array as PropType<Tool[]>, required: true},
  },
})
