<template>
  <ui-skeleton :loading="!tool" active :paragraph="{rows: 5}" :avatar="{shape: 'square', size: 300}">
    <ui-card class="tool_view" elevation="0">
      <div class="content">
        <ui-card-media v-if="tool.icon" class="image" square>
          <ui-card-media-content>
            <img :src="tool.icon" :alt="tool.name" />
          </ui-card-media-content>
        </ui-card-media>
        <div class="main">
          <ui-card-text class="text">
            <h5 :class="$tt('headline5')">{{ tool.name }}</h5>
            <!-- <div class="author">{{ tool.author }}</div> -->
            <!-- <p v-if="tool.launchDate"><span class="sub_title">When to use it: </span>{{ tool.launchDate }}</p> -->
            <p v-if="tool.pm" :class="$tt('body1')"><span class="sub_title">Product Manager: </span><span class="pm">{{ tool.pm }}</span></p>
            <p v-if="tool.shortDescription" :class="$tt('body1')"><span class="sub_title">Benefits of usage: </span>{{ tool.shortDescription }}</p>
            <p :class="$tt('body1')">{{ tool.longDescription }}</p>
          </ui-card-text>
          <ui-card-actions class="actions">
            <router-link class="launch" v-if="tool.isRetool" :to="{name: 'retool', params: {appId: tool.docId}}">
              <ui-button type="raised" @click="events.launchTool(tool), use(tool.docId)">Launch</ui-button>
            </router-link>
            <a v-else :href="tool.url" target="_blank" class="launch">
              <ui-button type="raised" @click="events.launchTool(tool), use(tool.docId)">Launch</ui-button>
            </a>
            <a href="https://branded.atlassian.net/servicedesk/customer/portal/5/group/17/create/96" target="_blank">
              <ui-button type="text" @click="events.requestTool(tool)">Request access</ui-button>
            </a>
          </ui-card-actions>
        </div>
      </div>
    </ui-card>
  </ui-skeleton>
</template>

<script lang="ts">
import type {PropType} from 'vue'
import type {Tool} from '@/types/Tool'

import {defineComponent} from 'vue'
import {useAnalytics} from '@/analytics'
import {useStore} from '@/store'

export default defineComponent({
  props: {
    tool: {type: Object as PropType<Tool | undefined>, required: true},
  },
  setup(){
    const store = useStore()
    const events = useAnalytics()

    const use = (toolId) => store.dispatch('tools/use', toolId)
  
    return {
      events,
      use
    }
  }
})
</script>

<style lang="scss" scoped>
.content {
  display: flex;
}

.image {
  width: 300px;
  flex: 0 0 auto;

  img {
    width: 100%;
    height: 100%;
    max-height: 400px;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    display: none;
  }
}

.text {
  flex: 1 0 auto;
  > :not(:last-child) {
    margin-bottom: 12px;
  }
}

.sub_title {
  font-weight: 600;
  text-transform: uppercase;
}

.pm {
  text-transform: capitalize;
}

.main {
  display: flex;
  flex-direction: column;
}

.actions {
  gap: 12px;
  @media (max-width: 425px) {
    flex-direction: column;

    > * {
      width: 100%;
    }
  }
}
.launch {
  min-width: 200px;

  > * {
    width: 100%;
  }
}
</style>
