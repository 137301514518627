<template>
  <router-view v-if="status !== 'unknown'" />
  <feedback-button />
  <ui-dialog v-model="isBlocked" @confirm="signOut">
    <ui-dialog-title>Sorry you not allowed to continue!</ui-dialog-title>
    <ui-dialog-content>
      <p>
        Your email should be registred under one of the organizations supported by the Branded Platform.
      </p>
      <p>Please ensure this is a corect email: <strong v-if="user">{{ user.email }}</strong></p>
    </ui-dialog-content>
    <ui-dialog-actions></ui-dialog-actions>
  </ui-dialog>
</template>

<script lang="ts">
import {defineComponent, computed, ref, watch} from 'vue'
import {useStore} from '@/store'

import BlockedMessage from '@/components/BlockedMessage.vue'
import FeedbackButton from '@/components/FeedbackButton.vue'

export default defineComponent({
  components: {
    BlockedMessage,
    FeedbackButton,
  },
  setup() {
    const store = useStore()
    const status = computed(() => store.state.user.status)
    const user = computed(() => store.state.user.data)
    const isBlocked = ref(false)
    const signOut = () => {
      console.log('signout')
      store.dispatch('user/signOut')
    }

    watch(() => status.value === 'blocked', (value) => isBlocked.value = value, {immediate: true})
    store.dispatch('user/auth')

    return {status, user, isBlocked, signOut}
  },
})
</script>

<style lang="scss" scoped>

</style>