import {debounce} from 'lodash'

//@ts-ignore
const gtag = window.gtag

export const events = {
  custom: ({name, payload}) => gtag('event', name, payload),
  signIn: () => gtag('event', 'login', {method: 'Google'}),
  launchTool: tool => gtag('event', 'tool_launch', tool),
  openTool: tool => gtag('event', 'tool_open', tool),
  requestTool: tool => gtag('event', 'tool_request', tool),
  search: debounce(search => gtag('event', 'search', {search_term: search}), 1000)
}

export function useAnalytics() {
  return events
}

