
import {defineComponent, ref, computed, onBeforeMount} from 'vue'
import {useRouter} from 'vue-router'
import {useStore} from '@/store'
import {useMq} from '@/composables/mq'
import {useAnalytics} from '@/analytics'
import Avatar from '@/components/Avatar.vue'
import Popup from '@/components/Popup.vue'
import NotificationList from '@/components/NotificationList.vue'

export default defineComponent({
  components: {
    Avatar,
    Popup,
    NotificationList,
  },
  emits: ['menu'],
  setup() {
    const mq = useMq()
    const store = useStore()
    const router = useRouter()
    const events = useAnalytics()

    const search = ref('')
    const autocomplete = computed(() => {
      if (!search.value) return []
      const regexp = new RegExp(search.value, 'i')
      return store.state.tools.list.reduce((variants, tool) => {
        if (regexp.test(tool.name)) variants.push({value: tool.docId, label: tool.name})
        return variants
      }, [])
    })
    const enterTool = ({value}) => {
      search.value = ''
      router.push({name: 'tool', params: {toolId: value}})
    }

    onBeforeMount(() => {
      store.dispatch('notifications/fetch')
      store.dispatch('notifications/watch')
    })
    const newNotificationsCount = computed(() => store.getters['notifications/unreaded'].length)
    const notificationsOpen = ref(false)

    const user = computed(() => store.state.user.data)
    const signOut = () => store.dispatch('user/signOut')

    return {
      mq,
      search,
      autocomplete,
      enterTool,
      newNotificationsCount,
      notificationsOpen,
      user,
      signOut,
      events
    }
  },
})
