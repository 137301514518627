<template>
  <div :class="['avatar', {compact}]">
    <img v-if="image" :src="image" :alt="name" />
    <span v-else class="fallback">{{ fallback }}</span>
  </div>
</template>

<script lang="ts">
import {defineComponent, computed} from 'vue'

export default defineComponent({
  props: {
    image: {type: String},
    name: {type: String},
    compact: {type: Boolean},
  },
  setup(props) {
    const fallback = computed(() => {
      const [firstName = '', lastName = ''] = props.name.split(' ', 2)
      return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`
    })

    return {fallback}
  },
})
</script>

<style lang="scss" scoped>
@use '@/assets/style/theme.scss' as theme;

.avatar {
  --avatar-size: 40px;
  --avatar-font-size: 16px;

  position: relative;
  border-radius: 50%;
  overflow: hidden;
  width: var(--avatar-size);
  height: var(--avatar-size);
  font-size: var(--avatar-font-size);

  &.large {
    --avatar-size: 48px;
    --avatar-font-size: 24px;
  }

  img {
    width: auto;
    max-height: 100%;
  }

  .fallback {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: theme.$primary;
    color: #ffffff;
  }
}
</style>
