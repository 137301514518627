
import {defineComponent, ref, watch, nextTick, onMounted, onUnmounted} from 'vue'
import Popper from 'popper.js'

export default defineComponent({
  props: {
    open: {type: Boolean}
  },
  setup(props, {emit}) {
    watch(() => props.open, () => nextTick(popper ? popper.scheduleUpdate : create))
    const popupRef = ref(null)
    const targetRef = ref(null)
    let popper = null
    const create = () => {
      popper = new Popper(targetRef.value, popupRef.value, {
        placement: 'bottom',
        modifiers: {
          offset: {
            offset: '-8px, 12px',
          },
          preventOverflow: {
            boundariesElement: document.body
          }
        },
      })
      popper.scheduleUpdate()
    }
    onUnmounted(() => popper?.destroy())

    const rootRef = ref(null)
    const close = event => {
      if (!props.open || rootRef.value.contains(event.target)) return
      event.preventDefault()
      emit('close')
    }
    onMounted(() => {
      document.addEventListener('mousedown', close)
      document.addEventListener('touchstart', close, {passive: false})
    })
    onUnmounted(() => {
      document.removeEventListener('mousedown', close)
      document.removeEventListener('touchstart', close)
    })

    return {
      rootRef,
      popupRef,
      targetRef,
    }
  },
})
