
import type {PropType} from 'vue'
import type {Tool} from '@/types/Tool'

import {defineComponent, computed} from 'vue'
import {useAnalytics} from '@/analytics'
import {useStore} from '@/store'

export default defineComponent({
  props: {
    compact: {type: Boolean},
    tool: {type: Object as PropType<Tool>},
  },
  setup() {
    const store = useStore()
    const events = useAnalytics()
    const use = (toolId) => store.dispatch('tools/use', toolId)

    return {
      events,
      use
    }
  }
})
