
import {defineComponent, computed, ref, watch} from 'vue'
import {useStore} from '@/store'

import BlockedMessage from '@/components/BlockedMessage.vue'
import FeedbackButton from '@/components/FeedbackButton.vue'

export default defineComponent({
  components: {
    BlockedMessage,
    FeedbackButton,
  },
  setup() {
    const store = useStore()
    const status = computed(() => store.state.user.status)
    const user = computed(() => store.state.user.data)
    const isBlocked = ref(false)
    const signOut = () => {
      console.log('signout')
      store.dispatch('user/signOut')
    }

    watch(() => status.value === 'blocked', (value) => isBlocked.value = value, {immediate: true})
    store.dispatch('user/auth')

    return {status, user, isBlocked, signOut}
  },
})
